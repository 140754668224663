/**
 * @file 导航栏的api
 * @author FengGuang(fengguang01@baidu.com)
 */

import request from 'superagent';

import baseRequest2 from '../baseApi2';

/**
 * 获取搜索结果
 *
 * @param {string} kw 搜索的关键字
 * @return {Object} promise对象
 */

interface IDocInfoProps {
    language?: 'zh' | 'en';
    version?: string;
}

export const fetchGetHeaderSearchSug = (kw: string, docInfo: IDocInfoProps): Promise<request.Response> => {
    // 本方法会被 paddle文档 项目引入并执行，paddle文档 项目中有指示文档语言和版本号的变量
    const theDocInfo: IDocInfoProps = {
        ...docInfo,
        ...(window as any).docInfo
    };
    const windowDocInfo = (window as any).docInfo || {};
    if ('lang' in windowDocInfo) {
        theDocInfo.language = windowDocInfo.lang;
    }
    return baseRequest2(
        request.get('/searchapi')
            .query({
                q: kw,
                language: theDocInfo.language,
                version: theDocInfo.version
            })
    );
};
