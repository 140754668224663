/**
 * @file 顶部导航栏
 * @author FengGuang(fengguang01@baidu.com)
 */

import {useCallback, useMemo, useState, useRef} from 'react';
import debounce from 'lodash.debounce';
import {RouteComponentProps} from 'react-router';
import omit from 'object.omit';

import {
    fetchGetHeaderSearchSug
} from '../../api/components/headerApi';

import {
    ISearchSugItem,
    ISearcuSugResItem
} from './type';


const useHeaderHooks = (props?: RouteComponentProps) => {
    const routePropsRef = useRef<RouteComponentProps | undefined>(props);
    routePropsRef.current = props;

    const defaultSearchKw = useMemo(() => {
        if (typeof (window) !== 'object' || !window.location) {
            return '';
        }
        if (window.location.pathname === '/searchdoc') {
            const searchParam = new URLSearchParams(window.location.search.slice(1));
            return searchParam.get('q') || '';
        }
        return '';
    }, []);

    const [searchKw, setSearchKw] = useState<string>(defaultSearchKw);
    const [searchSugLoading, setSearchSugLoading] = useState<boolean>(false);
    const [searchSug, setSearchSug] = useState<ISearchSugItem[]>([]);

    const debounceGetSearchSug = useMemo(() => {
        const getSearchSug = async (kw: string) => {
            setSearchSugLoading(true);
            const docInfo = (() => {
                const docInfo: { [key: string]: string | undefined } = {};
                if (window.location.pathname === '/searchdoc') {
                    const searchParam = new URLSearchParams(window.location.search.slice(1));
                    docInfo.language = searchParam.get('language') || undefined;
                    docInfo.version = searchParam.get('version') || undefined;
                }
                return omit(docInfo, val => typeof (val) !== 'undefined');
            })();
            let res;
            try {
                res = await fetchGetHeaderSearchSug(kw, docInfo);
            }
            catch (err) {
                // ignore
            }
            setSearchSugLoading(false);
            if (res) {
                const data: ISearcuSugResItem[] = res.body.data || [];

                const list = data.map((item) => {
                    const newItem: ISearchSugItem = {
                        key: item.title + item.path,
                        title: item.title,
                        link: item.path,
                        type: '',
                    };
                    if (item.path.indexOf('/api/') > -1 || item.path.indexOf('/api_cn/') > -1) {
                        newItem.type = 'Api';
                    }
                    else if (item.path.indexOf('/models/') > -1) {
                        newItem.type = 'Models';
                    }
                    else {
                        newItem.type = 'Doc';
                    }
                    return newItem;
                });
                setSearchSug(list);
            }
        };

        const debounceGetSearchSug = debounce(getSearchSug, 200);
        return (kw: string) => {
            setSearchSugLoading(true);
            return debounceGetSearchSug(kw);
        };
    }, []);

    const onSearchKwSelect = useCallback((value: string, option: any): void => {
        if ((window as any)._hmt) {
            const docInfo = {
                lang: 'zh', // emun: zh/en
                version: '',
                ...(window as any).docInfo
            };
            (window as any)._hmt.push([
                '_trackEvent',
                '文档搜索',
                '点击sug',
                `${searchKw},第${option.props.index}个,${docInfo.lang},${docInfo.version},${option.props.data.title}`
            ]);
        }

        window.location.href = option.props.data.link;
    }, [searchKw]);

    const submitSearch = useCallback((): void => {
        // 在 document 页面中从全局接收 文档参数
        const docInfo: any = {
            lang: 'zh',
            ...(window as any).docInfo
        };

        const searchParams = omit({
            q: searchKw,
            language: docInfo.lang,
            version: docInfo.version
        }, val => typeof (val) !== 'undefined');
        if (routePropsRef.current && routePropsRef.current.location.pathname === '/searchdoc') {
            // 如果当前是搜索页，从 url 中接收 language 和 version 参数
            const thisPageQuery = new URLSearchParams(routePropsRef.current.location.search.slice(1));
            const thisPageSearchParams = omit({
                language: thisPageQuery.get('language') || undefined,
                version: thisPageQuery.get('version') || undefined
            }, val => typeof (val) !== 'undefined');
            const thisSearchParams = {
                ...searchParams,
                ...thisPageSearchParams
            };
            const query = new URLSearchParams(thisSearchParams);
            routePropsRef.current.history.push(`/searchdoc?${query.toString()}`);
            window.scrollTo({top: 0});
        }
        else {
            const query = new URLSearchParams(searchParams);
            window.location.href = `/searchdoc?${query.toString()}`;
        }
    }, [searchKw]);

    return {
        searchKw,
        searchSugLoading,
        searchSug,

        onSearchKwChange: setSearchKw,
        debounceGetSearchSug,
        onSearchKwSelect,
        submitSearch,
    };
};

export default useHeaderHooks;
