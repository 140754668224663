/**
 * @file 顶部导航栏搜索框的sug列表
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';
import {AutoComplete, Divider} from 'antd';

import {DataSourceItemType} from 'antd/lib/auto-complete';

import {ISearchSugItem} from '../../type';

interface IProps {
    searchKw: string;
    searchSugLoading: boolean;
    searchSug: ISearchSugItem[];
    submitSearch: () => void;
}

const headerSearchSug = (props: IProps): DataSourceItemType[] => {
    const {
        searchKw,
        searchSug
    } = props;

    if (!searchKw) {
        return [];
    }

    const list = searchSug.map((item, index) => (
        <AutoComplete.Option
            key={item.key}
            value={item.key}
            title={item.title}
            className="paddle-header-search-drop-item"
            {...{
                data: item,
            }}
        >
            {item.title}
            <span className="paddle-header-search-drop-item-type">
                {item.type}
            </span>
        </AutoComplete.Option>
    ));

    return list;
};

const dropdownRender = (props: IProps) =>
    (menu: React.ReactNode): React.ReactNode => {
        const {
            searchKw,
            searchSugLoading,
            searchSug,
            submitSearch
        } = props;

        return (
            <div>
                {menu}
                <Divider className="paddle-header-search-drop-item-divider" />
                {
                    !!searchKw && searchSug.length === 0 && !searchSugLoading
                    && (
                        <div
                            className="paddle-header-search-drop-item-more"
                        >
                            找不到关于‘{searchKw}’的搜索结果
                        </div>
                    )
                }
                {
                    !!searchKw && searchSug.length === 0 && !!searchSugLoading
                    && (
                        <div
                            className="paddle-header-search-drop-item-more"
                        >
                            正在加载‘{searchKw}’的结果
                        </div>
                    )
                }
                {
                    !!searchKw && searchSug.length > 0
                    && (
                        <div
                            className="paddle-header-search-drop-item-more"
                            onMouseDown={submitSearch}
                        >
                            查看更多关于‘{searchKw}’的结果
                        </div>
                    )
                }
            </div>
        );
    };

export default headerSearchSug;
export {dropdownRender};
