/**
 * @file 顶部导航栏搜索框
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useCallback} from 'react';
import {AutoComplete, Input} from 'antd';

import headerSearchSug, {dropdownRender} from './HeaderSearchSug';

import {ISearchSugItem} from '../../type';


// 修改下拉弹窗的对齐位置，参考
// 参考 https://www.npmjs.com/package/rc-select   的 dropdownAlign
// 参考 https://www.npmjs.com/package/rc-trigger  的 popupAlign
// 由于 antd.AutoComplete 没有声明 builtinPlacements，这里使用解构语句绕过 props 检查
const dropdownAlignProps = {
    dropdownAlign: {
        points: ['tc', 'bc'],
        overflow: {
            adjustX: 1,
            adjustY: 1
        },
        offset: [-5, 12]
    }
};

interface IProps {
    searchKw: string;
    searchSug: ISearchSugItem[];
    searchSugLoading: boolean;
    onSearchKwChange: (value: string) => void;
    debounceGetSearchSug: (value: string) => void;
    onSearchKwSelect: (value: string, option: any) => void;
    menuGetPopupContainer: () => HTMLElement;
    submitSearch: () => void;
}


const HeaderSearch: React.FC<IProps> = React.memo(props => {
    const {
        searchKw,
        debounceGetSearchSug,
        onSearchKwChange,
        onSearchKwSelect,
        menuGetPopupContainer,
        submitSearch
    } = props;

    const thisOnSearchKwChange = useCallback(
        (value: any, option?: React.ReactNode | any): void => {
            const kw = ((option || {}).props || {}).title || value || '';
            onSearchKwChange(kw);
        },
        [onSearchKwChange]
    );

    const thisOnSearchKwSelect = useCallback(
        (value: any, option: React.ReactNode | any): void => {
            const kw = ((option || {}).props || {}).title || value || '';
            onSearchKwSelect(kw, option);
        },
        [onSearchKwSelect]
    );

    return (
        <div className="paddle-header-search-wrap">
            <AutoComplete
                allowClear
                className="paddle-header-search"
                dropdownClassName="paddle-header-search-drop"
                value={searchKw}
                onChange={thisOnSearchKwChange}
                onSearch={debounceGetSearchSug}
                onSelect={thisOnSearchKwSelect}
                defaultActiveFirstOption={false}
                dropdownMatchSelectWidth={false}
                dataSource={headerSearchSug(props)}
                dropdownRender={dropdownRender(props)}
                getPopupContainer={menuGetPopupContainer}
                dropdownStyle={{width: '100%'}}

                {...dropdownAlignProps}
            >
                <Input
                    placeholder="PaddlePaddle"
                    className="paddle-header-search-input"
                    prefix={
                        <i
                            className="paddle-header-search-input-icon"
                        />
                    }
                    onPressEnter={submitSearch}
                />
            </AutoComplete>
        </div>
    );
});

export default HeaderSearch;
