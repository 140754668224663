/**
 * @file PaddlePaddle 主站页脚
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';

import footerData from './footerData';
import baiduweixinqr from '../../images/paddlepaddleQr.jpg';
import qqqr from '../../images/qqqr.png';

const Footer: React.FC = () => {
    return (
        <footer className="paddle-footer">
            <div className="paddle-footer-container">
                {footerData.map(group => (
                    <div key={group.groupName} className="paddle-footer-group">
                        <div className="paddle-footer-group-title">
                            {group.groupName}
                        </div>
                        <div className="paddle-footer-item-list">
                            {group.list.map(item => (
                                <div
                                    className="paddle-footer-item"
                                    key={`${group.groupName},${item.name}`}
                                >
                                    {item.url
                                        ? <a target={item.urlTarget} href={item.url}>{item.name}</a>
                                        : item.name
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                <div className="paddle-footer-qr-wrap">
                    <div className="paddle-footer-qr-item">
                        <div className="paddle-footer-qr"
                             style={{backgroundImage: `url(${baiduweixinqr})`}}>
                            <img className="paddle-footer-qr-img" alt="paddle weixin qr"
                                 src={baiduweixinqr} />
                        </div>
                        <div className="paddle-footer-qr-title">
                            飞桨微信公众号
                        </div>
                    </div>
                    <div className="paddle-footer-qr-item">
                        <div className="paddle-footer-qr"
                             style={{backgroundImage: `url(${qqqr})`}}>
                            <img className="paddle-footer-qr-img" alt="paddle weixin qr"
                                 src={qqqr} />
                        </div>
                        <div className="paddle-footer-qr-title">
                            飞桨官方技术交流群
                            <div className="paddle-footer-qr-subtitle">
                                （QQ群号：796771754）
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="paddle-footer-bottom">
                ©Copyright 2019, PaddlePaddle developers.
            </div>
        </footer>
    );
};

export default Footer;
