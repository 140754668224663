/**
 * @file 404 页面
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';
import {Button} from 'antd';

import Header from '../../components/header/HeaderWithStore';
import Footer from '../../components/footer/footer';

const Case: React.FC = props => {
    return (
        <div className="page paddle-404-page">
            <Header />
            <div className="paddle-404-page-container">
                <div className="paddle-404-page-context">
                    <div className="paddle-404-page-img-wrap">
                        <div className="paddle-404-page-img" />
                    </div>
                    <div className="paddle-404-page-title">
                        Oops!
                    </div>
                    <div className="paddle-404-page-subtitle">
                        抱歉，页面丢失啦~
                    </div>
                    <div className="paddle-404-page-btn-group">
                        <a href="/">
                            <Button
                                size="large"
                                type="primary"
                                className="paddle-404-page-btn"
                            >
                                返回首页
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Case;
