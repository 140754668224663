/**
 * @file 页脚数据
 * @author FengGuang(fengguang01@baidu.com)
 */

export interface IFooterDataItem {
    name: string,
    url: string,
    urlTarget?: string
}

export interface IFooterDataGroup {
    groupName: string,
    list: IFooterDataItem[],
}

const footerData: IFooterDataGroup[] = [
    {
        groupName: '产品',
        list: [
            {
                name: 'AI Studio',
                url: 'https://aistudio.baidu.com/',
                urlTarget: '_blank'
            },
            {
                name: 'EasyDL',
                url: 'http://ai.baidu.com/easydl/',
                urlTarget: '_blank'
            },
            {
                name: 'EasyEdge',
                url: 'https://ai.baidu.com/easyedge/'
            },
            {
                name: '工具',
                url: '/tools'
            }
        ]
    },
    {
        groupName: '文档',
        list: [
            {
                name: '安装',
                url: '/documentation/docs/zh/beginners_guide/install/index_cn.html?from=paddlefooter',
                urlTarget: '_blank'
            },
            {
                name: 'API',
                url: '/documentation/docs/zh/api_cn/index_cn.html?from=paddlefooter',
                urlTarget: '_blank'
            },
            {
                name: '使用指南',
                url: '/documentation/docs/zh/user_guides/index_cn.html?from=paddlefooter',
                urlTarget: '_blank'
            }
        ]
    },
    {
        groupName: '资源',
        list: [
            {
                name: '模型和数据集',
                url: '/modelanddataset'
            },
            {
                name: '学习资料',
                url: '/learningmaterials'
            },
            {
                name: '应用案例',
                url: '/case'
            }
        ]
    },
    {
        groupName: '联系我们',
        list: [
            {
                name: 'GitHub',
                url: 'https://github.com/paddlepaddle/paddle',
                urlTarget: '_blank'
            },
            {
                name: 'Email',
                url: 'mailto:Paddle-better@baidu.com'
            }
        ]
    }
];

export default footerData;
