/**
 * @file 顶部导航栏的数据
 * @author FengGuang(fengguang01@baidu.com)
 */
import {IMenuDataItem} from './type';


// 根据 url 设置key，方便之后通过 url 匹配元素
const setKeyFromUrl = (item: IMenuDataItem[]): IMenuDataItem[] => {
    return item.map(subItem => {
        if (!subItem.key && subItem.url) {
            subItem.key = new URL(subItem.url, window.location.href).pathname;
        }
        else if (!subItem.key) {
            subItem.key = `$${subItem.title}`;
        }
        if (subItem.children) {
            subItem.children = setKeyFromUrl(subItem.children);
        }
        return subItem;
    });
};


const menuData: IMenuDataItem[] = setKeyFromUrl([
    {
        key: '',
        title: '开始使用',
        url: '/start'
    },
    {
        key: '',
        title: '特性',
        url: '/feature'
    },
    {
        key: '',
        title: '文档',
        url: '/documentation/docs/zh/beginners_guide/index_cn.html?from=paddlenav'
    },
    {
        key: '',
        title: '工具平台',
        children: [
            {
                key: '',
                title: '工具',
                children: [
                    {
                        key: '',
                        title: 'AutoDL Design',
                        url: 'https://github.com/PaddlePaddle/AutoDL/tree/master/AutoDL%20Design',
                        target: '_blank'
                    },
                    {
                        key: '',
                        title: 'PARL',
                        url: 'https://github.com/paddlepaddle/parl',
                        target: '_blank'
                    },
                    {
                        key: '',
                        title: 'ERNIE',
                        url: '/ernie'
                    },
                    {
                        key: '',
                        title: 'PaddleX',
                        url: '/paddle/paddleX'
                    },
                    {
                        key: '',
                        title: '全部',
                        url: '/tools'
                    }
                ]
            },
            {
                key: '',
                title: '服务平台',
                children: [
                    {
                        key: '',
                        title: 'AI Studio',
                        url: 'https://aistudio.baidu.com/',
                        target: '_blank'
                    },
                    {
                        key: '',
                        title: 'EasyDL',
                        url: 'http://ai.baidu.com/easydl/',
                        target: '_blank'
                    },
                    {
                        key: '',
                        title: 'EasyEdge',
                        url: 'https://ai.baidu.com/easyedge/',
                        target: '_blank'
                    }
                ]
            }
        ]
    },
    {
        key: '',
        title: '资源',
        children: [
            {
                key: '',
                title: '模型和数据集',
                url: '/modelanddataset'
            },
            {
                key: '',
                title: '学习资料',
                url: '/learningmaterials'
            },
            {
                key: '',
                title: '应用案例',
                url: '/case'
            }
        ]
    },
    {
        key: '',
        title: '模型库',
        url: '/modelbase',
    },
    {
        key: '',
        title: 'GitHub',
        url: 'http://github.com/paddlepaddle/paddle',
        target: '_blank'
    },
    {
        key: '',
        title: '资讯',
        url: '/support/news',
    }
]);

// 遍历元素找到当前页面 url 匹配的选项
const findMenu = (
    theMenuData: IMenuDataItem[],
    targetKey: string = window.location.pathname
): IMenuDataItem[] => {
    let resItem: IMenuDataItem[] = [];
    theMenuData.some((item) => {
        if (item.key === targetKey) {
            resItem.push(item);
            return true;
        }
        if (item.children) {
            const subResItem = findMenu(item.children, targetKey);
            if (subResItem.length) {
                resItem.push(item);
                resItem = resItem.concat(subResItem);
            }
        }
        return false;
    });
    return resItem;
};

export default menuData;
export {findMenu};
