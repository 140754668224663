/**
 * @file 顶部导航栏
 * @author FengGuang(fengguang01@baidu.com)
 */


import React, {useCallback, useRef, useState} from 'react';
import {Drawer, Menu} from 'antd';

import HeaderSearch from './components/header-search/HeaderSearch';

import headerData from './headerData';
import logoImg from '../../images/logo.png';
import logo2Img from '../../images/logo2.png';

import {isDocumentCanSearch} from './container';
import {IHeaderProps} from './type';

const MenuItemGroup = Menu.ItemGroup;
const MenuItem = Menu.Item;
const MenuSubMenu = Menu.SubMenu;
const MenuDivider = Menu.Divider;


const Header: React.FC<IHeaderProps> = React.memo(props => {
    const [menuVisible, setMenuVisible] = useState<boolean>(false);

    const backgroundColorOpacity = props.backgroundColorOpacity;

    const {
        searchKw,
        searchSug,
        searchSugLoading,

        onSearchKwChange,
        debounceGetSearchSug,
        onSearchKwSelect,
        submitSearch
    } = props;

    const headerWrapEl = useRef(null);

    const menuGetPopupContainer = useCallback(() => {
        return headerWrapEl.current
            || (document.querySelector('.paddle-header') as HTMLElement)
            || document.body;
    }, []);

    return (
        <div className="paddle-header-wrap" ref={headerWrapEl}>
            <div
                className="paddle-header-background"
                style={{opacity: backgroundColorOpacity}}
            />
            <div className="paddle-header">
                <div className="paddle-header-logo-item">
                    <a href="/" className="paddle-header-logo-wrap">
                        <img alt="logo" className="paddle-header-logo" src={logoImg} />
                    </a>
                </div>
                <div className="paddle-header-right">
                    {
                        isDocumentCanSearch
                        && <HeaderSearch
                            searchKw={searchKw}
                            searchSugLoading={searchSugLoading}
                            searchSug={searchSug}
                            onSearchKwChange={onSearchKwChange}
                            debounceGetSearchSug={debounceGetSearchSug}
                            onSearchKwSelect={onSearchKwSelect}
                            menuGetPopupContainer={menuGetPopupContainer}
                            submitSearch={submitSearch}
                        />
                    }

                    <i
                        className="paddle-header-menu-icon"
                        onClick={() => setMenuVisible(true)}
                    />
                </div>
                <Drawer
                    closable={false}
                    className="paddle-header-drawer"
                    visible={menuVisible}
                    width=""
                    onClose={() => setMenuVisible(false)}
                >
                    <button
                        className="paddle-header-drawer-close"
                        onClick={() => setMenuVisible(false)}
                    >
                        <i className="paddle-header-drawer-close-icon" />
                    </button>
                    <div className="paddle-header-drawer-logo-wrap">
                        <img
                            className="paddle-header-drawer-logo"
                            alt="logo"
                            src={logo2Img}
                        />
                    </div>
                    <div className="paddle-header-drawer-divider" />
                    <Menu
                        mode="inline"
                        className="paddle-header-drawer-menu"
                        inlineIndent={0}
                        overflowedIndicator={<div></div>}
                    >
                        {headerData.map(item => {
                            if (item.children && item.children.length > 0) {
                                return (
                                    <MenuSubMenu
                                        key={item.key}
                                        title={item.title}
                                    >
                                        {item.children.map(l2Item => {
                                            if (l2Item.children && l2Item.children.length > 0) {
                                                return ([
                                                    <MenuItemGroup
                                                        key={l2Item.key}
                                                        title={l2Item.title}
                                                    >
                                                        {l2Item.children.map(l3Item => (
                                                            <Menu.Item key={l3Item.key}>
                                                                {l3Item.url
                                                                    ? <a target={l3Item.target} href={l3Item.url}>
                                                                        {l3Item.title}
                                                                    </a>
                                                                    : l3Item.title
                                                                }
                                                            </Menu.Item>
                                                        ))}
                                                    </MenuItemGroup>,
                                                    <MenuDivider />
                                                ]);
                                            }
                                            return (
                                                <MenuItem key={l2Item.key}>
                                                    {l2Item.url
                                                        ? <a target={l2Item.target} href={l2Item.url}>
                                                            {l2Item.title}
                                                        </a>
                                                        : l2Item.title
                                                    }
                                                </MenuItem>
                                            );
                                        })}
                                    </MenuSubMenu>
                                );
                            }
                            return (
                                <MenuItem key={item.key}>
                                    {item.url
                                        ? <a target={item.target} href={item.url}>
                                            {item.title}
                                        </a>
                                        : item.title
                                    }
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </Drawer>
            </div>
        </div>
    );
});

export default Header;
